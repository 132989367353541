


















































import {vxm} from "@/store";
import {Component, Vue, Watch} from 'vue-property-decorator';
import {validationMixin} from "vuelidate"
import {required, minLength, email, sameAs, helpers} from "vuelidate/lib/validators"
import {RegisterOTPDTO} from "@shared_vue/types/RegisterOTPDTO";
import {SetPasswordDTO} from "@shared_vue/types/SetPasswordDTO";
import OkModal from "@/views/widgets/modals/OkModal.vue";    

@Component({
    components: {OkModal},
    mixins: [validationMixin],
    validations: {
        form: {
            password: {
                required,
                minLength: minLength(8),
                strongPass: helpers.regex('strongPass', /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/)
            },
            confirmPassword: {
                required,
                sameAsPassword: sameAs("password")
            }
        }
    }
})

export default class RegisterPassword extends Vue {
    private ui = vxm.ui;
    private form = this.getEmptyForm();
    private submitted = false;
    private page = 1;
    private showSuccess = false;
    private acked=false;

    @Watch('acked')
    onAcked(val: boolean, oldval: boolean){
        //redirect
        vxm.ui.RegisterState=1;
        this.$router.push('/dashboard');
    }

    getEmptyForm() {
        return {
            password: "",
            confirmPassword: "",
        }
    }

    get formString() {
        return JSON.stringify(this.form, null, 4);
    }

    get isValid() {
        return !this.$v.form.$invalid;
    }

    get isDirty() {
        return this.$v.form.$anyDirty;
    }

    checkIfValid(fieldName: any) {
        const field = this.$v.form[fieldName];
        if (field == undefined) {
            return false;
        }
        return !field.$dirty ? null : !(field.$invalid || field.$model === '');
    }

    submit() {                    
      if (this.isValid) {
        this.ui.setWaitForRegister(true);
        let dto = new SetPasswordDTO(<string>vxm.auth.usr?.uid,this.form.password, this.form.confirmPassword);
        this.submitted=true;

        vxm.auth.setPassword(dto).then(
            res=>{
              console.log('Succeeded with ' + res);
              if (res){
                //go to next screen. IRL more stuff would happen there.                    
                this.acked = true;                    
              }
            }
        ).catch(err=>console.log('Failed with err')).finally(()=>this.ui.setWaitForRegister(false));
      }
    }

    validate() {
        this.$v.$touch()
    }

    reset() {
        this.form = this.getEmptyForm()
        this.submitted = false
        this.$v.$reset()
    }

}
