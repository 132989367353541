




























































import { vxm } from "@/store";
import { Component, Vue } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  email,
  sameAs,
  helpers,
} from "vuelidate/lib/validators";
import RegisterOne from "@/views/viewcomponents/RegisterOne.vue";
import RegisterOTP from "@/views/viewcomponents/RegisterOTP.vue";
import RegisterPassword from "@/views/viewcomponents/RegisterPassword.vue";
import RegisterCellOnly from "@/views/viewcomponents/RegisterCellOnly.vue";

@Component({
  components: { RegisterCellOnly, RegisterPassword, RegisterOTP, RegisterOne },
})
export default class RegisterPort extends Vue {
  private ui = vxm.ui;

  private toggleAside() {
    this.ui.toggleAside();
  }
  
  private handleMemberDuplicate(arg: any) {
    if (arg==='otp'){
      vxm.ui.RegisterState = 2;
    }
  }
  
  mounted() {
    if (this.$route.query.registerlink) {
      this.ui.asideShow = true;
      this.ui.registerState = 1;
      //prepopulate some data
      let name = <string>this.$route.query.name;
      let cell = <string>this.$route.query.cell;
      let secret = <string>this.$route.query.secret;
      let firstName = name.split('-')[0];
      let lastName = name.split('-')[1];
      console.log('got new data to register: ' + firstName + ' ' + lastName + ' ' + cell + ' ' + secret);
    }
  }
}
