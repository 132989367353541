





































import {vxm} from "@/store";
import {Component, Vue} from 'vue-property-decorator';
import {validationMixin} from "vuelidate"
import {required, minLength, email, sameAs, helpers} from "vuelidate/lib/validators"
import {OtpConfirmData} from "@shared_vue/openapi/userapi";

@Component({
    components: {},
    mixins: [validationMixin],
    validations: {
        form: {
            otp: {
                required
            }
        }
    }
})

export default class RegisterOTP extends Vue {
    private ui = vxm.ui;
    private form = this.getEmptyForm();
    private submitted = false;
    private page = 1;
    private showError = false;
    private errorMessage = "";
    private toggleAside() {
        this.ui.toggleAside();
    }

    getEmptyForm() {
        return {
            otp: ""
        }
    }

    private resendOTP(){
      vxm.auth.reSendOTP().then(
          res=>{
            console.log('Succeeded with ' + res);
            this.errorMessage = "OTP Resent";
            this.showError = true;
          }
      ).catch(err=>{
        console.log('Failed with err')
        this.errorMessage = "Could Not Send OTP";
        this.showError = true;
      });
    }

    get formString() {
        return JSON.stringify(this.form, null, 4);
    }

    get isValid() {
        return !this.$v.form.$invalid;
    }

    get isDirty() {
        return this.$v.form.$anyDirty;
    }

    checkIfValid(fieldName: any) {
        const field = this.$v.form[fieldName]
        if (field == undefined) {
            return false;
        }
        return !field.$dirty ? null : !(field.$invalid || field.$model === '');
    }

    submit() {
      if (this.isValid) {
        this.ui.setWaitForRegister(true);
        let dto = <OtpConfirmData>{otp:this.form.otp, userUid:<string>vxm.auth.usr?.uid};
        this.submitted=true;

          vxm.auth.sendOTP(dto).then(
              res=>{
                console.log('Succeeded with ' + res);
                if (res){
                  //go to next screen. IRL more stuff would happen there.
                  vxm.ui.RegisterState=3;
                }
                // vxm.ui.RegisterState=1;
                // this.$router.push('/dashboard');
              }
          ).catch(err=>console.log('Failed with err')).finally(()=>{
            this.ui.setWaitForRegister(false);
          });
      }
    }

    validate() {
        this.$v.$touch()
    }

    reset() {
        this.form = this.getEmptyForm()
        this.submitted = false
        this.$v.$reset()
    }

}
